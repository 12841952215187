import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import folleto from "../../assets/images/Folleto.jpg";
import SEO from "../../components/SEO/SEO";

const Step4 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Education System and VET in Spain | ${titleTrainingDays}`}
        description={"Explanation about how the Spanish Education System manages the atention to diversity"}
        canonical={"training-days/step-4"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 4{" "}
        </p>
        <h1 className={"slideLeft"}>
          Education System and <span className={"uppercase"}>VET</span> in Spain
        </h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <div className={"embed-iframe"}>
              <img src={folleto} width={"1280"} height={"720"} />
            </div>
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-3/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-5-0/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>
              Explanation about how the Spanish Education System manages the atention to diversity
              <br />
              <a
                className={"pdf"}
                href={"/downloads/spanish-education-system-PP.pdf"}
                rel="noreferrer"
                target={"_blank"}
              >
                spanish education
              </a>
            </p>
            <p>
              Explanation about Vocational education and training in Aragón and profesional qualifications.
              <br />
              <a className={"pdf"} href={"/downloads/VETinAragonPP.pdf"} rel="noreferrer" target={"_blank"}>
                VET
              </a>
            </p>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step4;
